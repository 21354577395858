import React, { useEffect, useState } from "react";
import "./home.css";
import { Link } from "react-router-dom";
import { FeedbackFish } from "@feedback-fish/react";
import { useStateValue } from "../../StateProvider";

const Home = () => {
  const [{ alertMessage }] = useStateValue();
  const [message, setMessage] = useState("");
  useEffect(() => {
    setMessage(alertMessage);
  }, [alertMessage]);

  return (
    <React.Fragment>
      <div className="jumbotron jumbotron-fluid hero">
        <div className="container">
          <h1 className="display-4">Samstagsbrötchen</h1>
          <p className="lead">Jeden Samstag frische Brötchen.</p>
        </div>
      </div>

      <div className="container mb-5">
        {message && (
          <div
            className="alert alert-danger"
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
        )}
        <div className="card">
          <div className="card-header">Willkommen</div>
          <div className="card-body">
            <h5 className="card-title">
              Willkommen im Webshop des Prima-Klima-Samstagsbrötchen-Angebot
            </h5>
            <p className="paragraph">
              Bitte behalte im Hinterkopf, dass es sich hierbei um eine frühe
              Version der App handelt. Da wir das Projekt stetig
              weiterentwickeln und verbessern, kann es vereinzelt zu Fehlern
              kommen. Wir freuen uns hierbei natürlich über Feedback,
              Fehlermeldungen oder Anregungen!
            </p>
            <Link to="/products" className="btn btn-success">
              Zum Angebot
            </Link>
            <FeedbackFish projectId="ae904306ddad3c">
              <button className="btn btn-warning ml-2">
                Fehler melden &amp; Feedback
              </button>
            </FeedbackFish>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-sm-6">
            <h2>Wir funktioniert das Ganze?</h2>
            <br />
            <p>
              Warum immer mit dem Auto zum Bäcker fahren, wenn das Ganze auch
              ganz bequem geht? Lasse dir deine Brötchen jeden Samstag morgen
              frisch liefern - und tue dabei noch etwas Gutes für das Klima! Das
              ist die Idee des Samstagsbrötchenangebotes vom Projekt Prima
              Klima.
            </p>
            <p>
              <b>Hinweis:</b> Da unsere Zusteller mit dem Fahrrad ausliefern,
              kann nur in Weissach im Tal geliefert werden.
            </p>
            <p>
              <b>Und so einfach geht´s:</b>
              <br />
              <br />
              <ol>
                <li>
                  Bis Freitag <b>17:00</b> Uhr online bestellen
                </li>
                <li>
                  Vor dem Haus eine Ablagekiste deiner Wahl mit dem Geld
                  bereitlegen (Die Lieferant*innen freuen sich über ein kleines
                  Trinkgeld)
                </li>
                <li>
                  Die Brötchen werden dann Samstag morgens <b>frisch </b>{" "}
                  geliefert
                </li>
              </ol>
            </p>
            <br />
            <p>
              <b>
                Das Samstagsbrötchen-Projekt ist ein gemeinnütziges Projekt.
                Abgesehen von dem Trinkgeld an die Austräger*innen wird hiermit
                kein Profit erzielt.
              </b>
            </p>
          </div>
          <div className="col-sm-6">
            <img
              className="img-fluid"
              src={require("../../img/person-brunno.jpg")}
              alt="Person an Kasse"
            />
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-sm-6">
            <img
              className="img-fluid"
              src={require("../../img/trefz.jpg")}
              alt="Bäckerei Trefz Logo"
            />
          </div>
          <div className="col-sm-6">
            <h2>Woher kommen die Brötchen?</h2>
            <br />
            <p>
              Du kannst Brötchen, Brezeln, Brot und Konditoreiwaren unserer
              regionalen Bäckerei &amp; Konditorei Trefz bestellen. Mehr
              Informationen zu dem traditionellen Familienbetrieb in
              Unterweissach findest du hier:
              <br />
              <a href="http://www.baeckerei-trefz.de/">baeckerei-trefz.de/</a>
              <br />
              <br />
              <b>Warum machen wir das? </b>
              <br />
              Prima Klima ist ein Förderprogramm das vom Bundesministerium für
              Umwelt-Naturschutz und nukleare Sicherheit aufgrund eines
              Beschlusses des Deutschen Bundestags gefördert wird. Ziel ist es
              im Alltag etwas für den Klimaschutz zu tun.Wir haben beobachtet,
              dass sich häufig am Wochenende der Einkauf lediglich aufs
              Brötchenholen reduziert. Nur für diesen einzelnen Weg wird häufig
              das Auto genutzt und in Summe viel CO2 verbraucht. Wenn wir die
              Brötchen liefern, spart ihr CO2, könnt länger schlafen und müsst
              nicht vor der Bäckerei in der Schlange anstehen.
            </p>
            <div className="row mt-5">
              <img src={require("../../img/BMU-logo.jpg")} alt="BMU-Logo" />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Home;
