import React from "react";
import { Link } from "react-router-dom";

const HasOrder = () => {
  return (
    <React.Fragment>
      <div className="container ">
        <div className="d-flex justify-content-center align-items-center basket-empty">
          <h2 className="display-4">Du hast bereits eine aktive Bestellung.</h2>
          <p className="lead">
            Gehe auf dein <Link to="/account">Profil</Link> und entferne diese
            um eine neue Bestellung aufzugeben.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
};

export default HasOrder;
