import React, { useEffect, useState } from "react";
import fireDb from "../../database/Firebase";

const AdminOrders = () => {
  const [loaded, setLoaded] = useState(false);
  const [orders, setOrders] = useState(0);
  const [users, setUsers] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (loaded) return;
    fireDb
      .database()
      .ref("/orders/")
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          setOrders(snapshot.numChildren());
          snapshot.forEach((tmp) => {
            setTotal(total + tmp.val().total);
          });
        }
        setLoaded(true);
      });
    fireDb
      .database()
      .ref("/users/")
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          setUsers(snapshot.numChildren());
        }
      });
  });

  return (
    <div className="container">
      <div className="row">
        <div className="col">
          <h4>Bestellungen</h4>
        </div>
        <div className="col">
          <p className="lead">{orders}</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Registrierte Nutzer</h4>
        </div>
        <div className="col">
          <p className="lead">{users}</p>
        </div>
      </div>
      <div className="row">
        <div className="col">
          <h4>Bestellungswert</h4>
        </div>
        <div className="col">
          <p className="lead">{total} €</p>
        </div>
      </div>
    </div>
  );
};

export default AdminOrders;
