import React from "react";

const Unauthorized = () => {
  return (
    <React.Fragment>
      <div className="container ">
        <div className="d-flex justify-content-center align-items-center basket-empty">
          <h2 className="display-4">
            Du hast keine Berechtigung um das zu tun!
          </h2>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Unauthorized;
