import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import fireDb from "../../database/Firebase";

const Login = () => {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [alertMessage, setAlertMessage] = useState();

  useEffect(() => {
    if (fireDb.auth().currentUser != null) {
      history.push("/account");
    }
  });

  const userLogin = () => {
    if (password == null || email == null) {
      setAlertMessage("Bitte fülle alle Felder aus");
      return;
    }
    if (!validateEmail(email)) {
      setAlertMessage("Bitte gib eine gültige E-Mail an");
      return;
    }
    fireDb
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        history.push("/");
      })
      .catch((err) => setAlertMessage("Fehler: Bitte versuche es erneut."));
  };

  //Validation Reg-Ex
  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <div style={{ minHeight: "60vh" }}>
      <div className="container text-center pt-5">
        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
          <div className="card p-3 shadow border-0">
            <form className="form-signin">
              <img
                className="mb-4"
                src={require("../../img/wkk-icon.png")}
                alt=""
                width="72"
                height="72"
              />
              <h1 className="h3 mb-3 font-weight-normal">
                Bitte melde dich an
              </h1>
              {alertMessage ? (
                <div className="alert alert-danger">{alertMessage}</div>
              ) : (
                " "
              )}
              <label className="sr-only">Email address</label>
              <input
                type="email"
                className="form-control"
                placeholder="Email address"
                autoComplete="off"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
              />
              <label className="sr-only">Password</label>
              <input
                type="password"
                className="mt-3 form-control"
                placeholder="Password"
                autoComplete="current-password"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
              />
              <div
                className="mt-3 btn btn-lg btn-primary btn-block"
                onClick={userLogin}
              >
                Anmelden
              </div>
              <div className="mt-3">
                <Link to="/register">Kein Account? Hier registrieren!</Link>
              </div>
              <p className="mt-5 mb-3 text-muted">© 2020</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
