import React, { useEffect, useState } from "react";
import { useStateValue } from "../../StateProvider";
import BasketContent from "../../components/BasketContent/BasketContent";
import BasketEmpty from "../../components/BasketEmpty/BasketEmpty";
import fireDb from "../../database/Firebase";
import HasOrder from "../../components/HasOrder/HasOrder";
import LoginContext from "../../components/LoginContext/LoginContext";

const Basket = () => {
  const [{ basket }] = useStateValue();
  const [loaded, setLoaded] = useState(false);
  const [auth, setAuth] = useState(false);
  const [hasOrder, setHasOrder] = useState(false);

  useEffect(() => {
    if (loaded) return;
    if (fireDb.auth().currentUser != null) {
      setAuth(true);
    } else return;
    fireDb
      .database()
      .ref("/users/" + fireDb.auth().currentUser.uid + "/orders")
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          setHasOrder(true);
        }
        setLoaded(true);
      });
  }, [loaded]);

  return (
    <React.Fragment>
      <div className="container mt-5">
        <div className="col">
          {basket.length === 0 ? (
            <BasketEmpty />
          ) : (
            <div>
              {!auth ? (
                <LoginContext />
              ) : (
                <div>{!hasOrder ? <BasketContent /> : <HasOrder />}</div>
              )}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Basket;
