import React, { useState, useEffect } from "react";
import fireDb from "../../database/Firebase";

const AccountInfo = () => {
  const [loaded, setLoaded] = useState(false);

  const [name, setName] = useState("");
  const [surname, setSurName] = useState("");
  const [adress, setAdress] = useState("");
  const [city, setCity] = useState("");

  useEffect(() => {
    if (loaded) return;
    if (fireDb.auth().currentUser == null) return;
    fireDb
      .database()
      .ref("users/" + fireDb.auth().currentUser.uid)
      .once("value")
      .then((snapshot) => {
        if (snapshot != null) {
          setName(snapshot.val().name);
          setSurName(snapshot.val().lastName);
          setAdress(snapshot.val().adress);
          setCity(snapshot.val().city);
          setLoaded(true);
        }
      })
      .catch((err) => console.log(err));
  });

  return (
    <div className="container">
      <div className="container ml-0">
        <h4>Persönliche Informationen</h4>
        <div className="card p-3">
          <div className="row">
            <div className="col">
              <p>{name}</p>
              <p>{surname}</p>
            </div>
            <div className="col">
              <p>{adress}</p>
              <p>{city}</p>
            </div>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default AccountInfo;
