import React from "react";
import fireDb from "../../database/Firebase";
import AccountPage from "../../components/AccountPage/AccountPage";
import LoginContext from "../../components/LoginContext/LoginContext";

const Account = () => {
  return (
    <div>
      <div className="container">
        {fireDb.auth().currentUser ? <AccountPage /> : <LoginContext />}
      </div>
    </div>
  );
};

export default Account;
