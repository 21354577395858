import React, { useEffect, useState } from "react";
import AdminArticleRow from "../../components/AdminArticleRow/AdminArticleRow";
import EditProduct from "../../components/EditProduct/EditProduct";
import fireDb from "../../database/Firebase";

const AdminArticles = () => {
  const [items] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [modal, setModal] = useState(<p>Not Loaded</p>);

  useEffect(() => {
    if (loaded) return;
    fireDb
      .database()
      .ref("products")
      .once("value")
      .then((snapshot) => {
        if (!snapshot != null) {
          snapshot.forEach((it) => {
            items.push(it);
          });
        }
        setLoaded(true);
      });
  });

  const updateModal = (item) => {
    setModal(null);
    setTimeout(3000);
    setModal(<EditProduct item={item} />);
  };

  return (
    <div className="container mt-5">
      <div className="row">
        <h1>Artikelliste</h1>
        <br />
      </div>
      <div className="row">
        <table className="table">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Produkt</th>
              <th scope="col">Beschreibung</th>
              <th scope="col">Stückpreis</th>
              <th scope="col">Aktiv?</th>
              <th scope="col">Aktionen</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, index) => (
              <AdminArticleRow
                key={index}
                itemSnapshot={item}
                index={index}
                editModal={(item) => updateModal(item)}
              />
            ))}
          </tbody>
        </table>
        <div
          className="modal fade"
          id="editModal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="editModalLable"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="editModalLable">
                  Produkt
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">{modal}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminArticles;
