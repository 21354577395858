import React, { useEffect, useState } from "react";
import Product from "../product/Product";
import fireDb from "../../database/Firebase";

const ProductList = () => {
  const [items] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) return;
    fireDb
      .database()
      .ref("products")
      .once("value")
      .then((snapshot) => {
        if (!snapshot != null) {
          snapshot.forEach((it) => {
            if (it.val().active) {
              items.push(it);
            }
          });
        }
        setLoaded(true);
      });
  });

  return (
    <div className="container">
      <div className="col">
        <h5>Produktliste</h5>
        <hr />
        <div className="row">
          {items.map((it, index) => (
            <Product key={index} item={it.val()} itemId={it.key} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductList;
