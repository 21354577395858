import React, { useEffect, useState } from "react";
import fireDb from "../../database/Firebase";

const OrderTableRow = ({ order, index }) => {
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [phone, setPhone] = useState("");
  const [district, setDistrict] = useState("");
  const [items] = useState(" ");

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) return;
    if (
      fireDb.auth().currentUser.uid === "IMX69FjgIRWiLZLqxayW1uPIGTb2" ||
      fireDb.auth().currentUser.uid === "70SpnAZtb0S0VEieWTSMx7MR0Ob2"
    ) {
      fireDb
        .database()
        .ref("users/" + order.userId)
        .once("value")
        .then((snapshop) => {
          if (snapshop.exists()) {
            setName(snapshop.val().lastName + " " + snapshop.val().name);
            setLocation(snapshop.val().adress + " - " + snapshop.val().city);
            setPhone(snapshop.val().phone);
            setDistrict(snapshop.val().district);
          }
        });
    }
    order.basket.forEach((element) => {
      let res = element.quantity + " x " + element.name + "; ";
      items.concat(res);
      setLoaded(true);
    });
  });

  return (
    <tr key={index}>
      <th scope="row">{index + 1}</th>
      <td>
        <p>{name}</p>
        <p>{phone}</p>
      </td>
      <td>
        <p>{location}</p>
        <p>{district}</p>
      </td>
      <td>
        {order.basket.map((it, index) => (
          <p key={index}>{it.quantity + "x" + it.name + ";"}</p>
        ))}
      </td>
      <td>{((order.total * 100) / 100).toFixed(2) + " €"}</td>
      <td>{order.date.split("T")[0]}</td>
    </tr>
  );
};

export default OrderTableRow;
