import React, { useEffect, useState } from "react";
import fireDb from "../../database/Firebase";
import { Link, useHistory } from "react-router-dom";
import Order from "../Order/Order";
import { useStateValue } from "../../StateProvider";

const AccountPage = () => {
  const history = useHistory();

  const [loaded, setLoaded] = useState(false);

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [adress, setAdress] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [district, setDistrict] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    if (!loaded) {
      if (fireDb.auth().currentUser == null) return;
      fireDb
        .database()
        .ref("users/" + fireDb.auth().currentUser.uid)
        .once("value")
        .then((snapshot) => {
          if (snapshot != null) {
            setName(snapshot.val().name);
            setLastName(snapshot.val().lastName);
            setAdress(snapshot.val().adress);
            setCity(snapshot.val().city);
            setEmail(snapshot.val().email);
            setPhone(snapshot.val().phone);
            setDistrict(snapshot.val().district);
            setIsAdmin(snapshot.val().admin);
            if (snapshot.val().orders != null) {
              checkOrder(snapshot.val().orders[0]);
            }
            setLoaded(true);
          }
        })
        .catch((err) => console.log(err));
    }
  });

  const [{ alertMessage }] = useStateValue();
  const [message, setMessage] = useState("");
  useEffect(() => {
    setMessage(alertMessage);
  }, [alertMessage]);

  const checkOrder = (order) => {
    fireDb
      .database()
      .ref("/orders/" + order)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) return;
        fireDb
          .database()
          .ref("/orders/" + order)
          .remove();
        fireDb
          .database()
          .ref("/users/" + fireDb.auth().currentUser.uid + "/orders")
          .remove();
      });
  };

  const logoutUser = () => {
    fireDb.auth().signOut();
    history.push("/");
  };

  return (
    <div>
      <div
        className="container"
        style={{ minHeight: "70vh", marginTop: "2rem" }}
      >
        <div className="row">
          {/* <div className="alert alert-danger">
            <b>Achtung:</b> Am Samstag, den 8. April 2023 erfolgen keine
            Lieferungen. Ab dem 15. April werden wie gewohnt frische Brötchen
            geliefert.
          </div> */}
          <div className="col">
            {message && (
              <div
                className="alert alert-danger"
                dangerouslySetInnerHTML={{ __html: message }}
              ></div>
            )}
            <div className="alert alert-warning">
              Bitte aktualisiere deine Account-Daten (Teilort und Telefonnummer)
              um die Zulieferung zu erleichtern oder bei Ausfall einer Lieferung
              informiert zu werden. <Link to="/edit/edit">Klicke hier</Link>
            </div>
            <div className="alert alert-info">
              <b>Hinweis:</b> Da die Zustellung per Fahrrad erfolgt, kann nur
              innerhalb Weissach im Tal geliefert werden.
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col mt-5">
            <div className="card border-0 shadow p-3">
              <div className="">
                <h2 className="text-center">Dein Konto</h2>
              </div>
              <hr />
              <br />
              <div className="row">
                <div className="col">
                  <h4>Vorname</h4>
                  <p>{name}</p>
                </div>
                <div className="col">
                  <h4>Nachname</h4>
                  <p>{lastName}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <h4>Straße</h4>
                  <p>{adress}</p>
                </div>
                <div className="col">
                  <h4>Stadt</h4>
                  <p>{city}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <h4>E-Mail</h4>
                  <p>{email}</p>
                </div>
                <div className="col">
                  <h4>Telefon</h4>
                  <p>{phone}</p>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <h4>Teilort</h4>
                  <p>{district}</p>
                </div>
              </div>
              <hr />
              <div className="row mt-2">
                <div className="container">
                  <div className="d-flex justify-content-around">
                    <Link to="/edit/edit" className="btn btn-secondary">
                      Bearbeiten
                    </Link>
                    <div className="btn btn-primary" onClick={logoutUser}>
                      Logout
                    </div>
                    {isAdmin && (
                      <Link to="/admin" className="btn btn-danger">
                        Admin
                      </Link>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Order />
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
