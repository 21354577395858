export const initialState = {
  basket: [],
  user: null,
  alertMessage: "",
};

function reducer(state, action) {
  switch (action.type) {
    case "ADD_TO_BASKET":
      //Logic for adding to basket
      return {
        ...state,
        basket: [...state.basket, action.payload],
      };
    case "REMOVE_FROM_BASKET":
      //Logic for removing item

      let newBasket = [...state.basket];

      const index = state.basket.findIndex(
        (basketItem) => basketItem.id === action.id
      );
      if (index >= 0) {
        //Item exists in basket
        newBasket.splice(index, 1);
      } else {
        console.warn("Can't remove product with id: " + action.id);
      }

      return { ...state, basket: newBasket };
    case "CLEAR_BASKET":
      return { ...state, basket: [] };
    case "SET_ALERT":
      return { ...state, alertMessage: action.payload };
    default:
      return state;
  }
}

export default reducer;
