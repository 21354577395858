import React from "react";
import "./basketEmpty.css";

const BasketEmpty = () => {
  return (
    <React.Fragment>
      <div className="container ">
        <div className="d-flex justify-content-center align-items-center basket-empty">
          <h2 className="display-4">Dein Warenkorb ist leer.</h2>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BasketEmpty;
