import React, { useState } from "react";
import database from "../../database/Firebase";
import "./ImageUpload.css";

const ImageUpload = ({ onSetUrl }) => {
  const allInputs = { imgUrl: "" };
  const [imageAsFile, setImageAsFile] = useState("");
  const [imageAsUrl, setImageAsUrl] = useState(allInputs);
  const [hasImage, setHasImage] = useState(false);

  const handleImageAsFile = (e) => {
    const image = e.target.files[0];
    setImageAsFile(image);
  };

  const handleUpload = (e) => {
    e.preventDefault();
    console.log("Image upload starting");
    if (imageAsFile === "") {
      console.log("Error, no image found");
      return;
    }
    const uploadTask = database
      .storage()
      .ref(`/images/${imageAsFile.name}`)
      .put(imageAsFile);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        console.log(snapshot);
      },
      (err) => {
        console.log(err);
      },
      () => {
        database
          .storage()
          .ref("images")
          .child(imageAsFile.name)
          .getDownloadURL()
          .then((firebaseUrl) => {
            setImageAsUrl((prevObject) => ({
              ...prevObject,
              imgUrl: firebaseUrl,
            }));
            console.log(firebaseUrl);
            setHasImage(true);
            onSetUrl(firebaseUrl);
          });
      }
    );
  };

  return (
    <div className="mt-4">
      <h3>Bild hochladen</h3>
      <hr />
      <div className="col mx-auto text-center">
        {hasImage && (
          <img
            className="imageUpload text-center"
            src={imageAsUrl.imgUrl}
            alt="Bakery"
          />
        )}
      </div>
      <form>
        <input type="file" onChange={(event) => handleImageAsFile(event)} />
        <div onClick={handleUpload} className="btn btn-secondary">
          Upload
        </div>
      </form>
    </div>
  );
};

export default ImageUpload;
