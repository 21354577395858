import React from "react";

const Imprint = () => {
  return (
    <React.Fragment>
      <div className="jumbotron jumbotrin fluid pHeader">
        <div className="container">
          <h1 className="display-4">Kontakt</h1>
          <p className="lead">Unsere Kontaktmöglichkeiten</p>
        </div>
      </div>
      <div className="container">
        <div className="impressum">
          <h1>Impressum</h1>
          <p>Angaben gemäß § 5 TMG</p>
          <p>
            Silke Müller-Zimmermann <br />
            Welzheimer Straße 43
            <br />
            71554 Weissach im Tal <br />
          </p>
          <p>
            {" "}
            <strong>Vertreten durch: </strong>
            <br />
            Silke Müller-Zimmermann
            <br />
          </p>
          <p>
            <strong>Haftungsausschluss: </strong>
            <br />
            <br />
            <strong>Haftung für Inhalte</strong>
            <br />
            <br />
            Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für
            die Richtigkeit, Vollständigkeit und Aktualität der Inhalte können
            wir jedoch keine Gewähr übernehmen. Als Diensteanbieter sind wir
            gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
            allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir
            als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder
            gespeicherte fremde Informationen zu überwachen oder nach Umständen
            zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
            Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
            Informationen nach den allgemeinen Gesetzen bleiben hiervon
            unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem
            Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
            Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese
            Inhalte umgehend entfernen.
            <br />
            <br />
            <strong>Datenschutz</strong>
            <br />
            <br />
            Die Nutzung unserer Webseite ist in der Regel ohne Angabe
            personenbezogener Daten möglich. Soweit auf unseren Seiten
            personenbezogene Daten (beispielsweise Name, Anschrift oder
            eMail-Adressen) erhoben werden, erfolgt dies, soweit möglich, stets
            auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche
            Zustimmung nicht an Dritte weitergegeben. <br />
            Wir weisen darauf hin, dass die Datenübertragung im Internet (z.B.
            bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann.
            Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist
            nicht möglich. <br />
            Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
            Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
            angeforderter Werbung und Informationsmaterialien wird hiermit
            ausdrücklich widersprochen. Die Betreiber der Seiten behalten sich
            ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung
            von Werbeinformationen, etwa durch Spam-Mails, vor.
            <br />
          </p>
          <br />
          Website Impressum von{" "}
          <a href="https://www.impressum-generator.de">
            impressum-generator.de
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Imprint;
