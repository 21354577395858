import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import fireDb from "../../database/Firebase";

const EditAccountPage = () => {
  const history = useHistory();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [adress, setAdress] = useState("");
  const [city, setCity] = useState("");
  const [district, setDistrict] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");

  useEffect(() => {
    if (fireDb.auth().currentUser == null) return;
    fireDb
      .database()
      .ref("users/" + fireDb.auth().currentUser.uid)
      .once("value")
      .then((snapshot) => {
        if (snapshot != null) {
          setName(snapshot.val().name);
          setLastName(snapshot.val().lastName);
          setAdress(snapshot.val().adress);
          setCity(snapshot.val().city);
          setEmail(snapshot.val().email);
          setDistrict(snapshot.val().district ? snapshot.val().district : "");
          setPhone(snapshot.val().phone ? snapshot.val().phone : "");
        }
      })
      .catch((err) => console.log(err));
  }, []);

  const updateData = () => {
    fireDb
      .database()
      .ref("/users/" + fireDb.auth().currentUser.uid)
      .update({
        name: name,
        lastName: lastName,
        adress: adress,
        city: city,
        email: email,
        district: district,
        phone: phone,
      })
      .then(() => history.push("/account"))
      .catch((err) => console.log(err));
  };

  return (
    <React.Fragment>
      <div
        className="container"
        style={{ minHeight: "70vh", marginTop: "2rem" }}
      >
        <div className="row">
          <div className="col mt-5">
            <div className="card border-0 shadow p-3">
              <div className="">
                <h2 className="text-center">Dein Konto</h2>
              </div>
              <hr />
              <br />
              <div className="row">
                <div className="col">
                  <h4>Vorname</h4>
                  <input
                    className="form-control"
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                </div>
                <div className="col">
                  <h4>Nachname</h4>
                  <input
                    className="form-control"
                    value={lastName}
                    onChange={(event) => setLastName(event.target.value)}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <h4>Straße</h4>
                  <input
                    className="form-control"
                    value={adress}
                    onChange={(event) => setAdress(event.target.value)}
                  />
                </div>
                <div className="col">
                  <h4>Stadt</h4>
                  <input
                    className="form-control"
                    value={city}
                    onChange={(event) => setCity(event.target.value)}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <h4>E-Mail</h4>
                  <input
                    className="form-control"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                </div>
                <div className="col">
                  <h4>Telefon</h4>
                  <input
                    className="form-control"
                    value={phone}
                    onChange={(event) => setPhone(event.target.value)}
                  />
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col">
                  <h4>Teilort</h4>
                  <input
                    className="form-control"
                    value={district}
                    onChange={(event) => setDistrict(event.target.value)}
                  />
                </div>
              </div>
              <hr />
              <div className="row mt-2">
                <div className="container">
                  <div className="d-flex justify-content-around">
                    <div className="btn btn-success" onClick={updateData}>
                      Benutzerdaten aktualisieren
                    </div>
                    <Link to="/account" className="btn btn-danger">
                      Änderungen verwerfen
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default EditAccountPage;
