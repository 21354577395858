import React, { useState, useEffect } from "react";
import fireDb from "../../database/Firebase";
import OrderTableRow from "./OrderTableRow";

const OrderTable = () => {
  const [items] = useState([]);
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (loaded) return;
    if (fireDb.auth().currentUser == null) return;
    if (
      fireDb.auth().currentUser.uid === "IMX69FjgIRWiLZLqxayW1uPIGTb2" ||
      fireDb.auth().currentUser.uid === "70SpnAZtb0S0VEieWTSMx7MR0Ob2"
    ) {
      fireDb
        .database()
        .ref("orders")
        .once("value")
        .then((snapshot) => {
          if (!snapshot != null) {
            snapshot.forEach((it) => {
              items.push(it.val());
            });
          }
          setLoaded(true);
        });
    }
  });

  /*
 <div>
        <ReactToPrint
          trigger={() => <a href="#">Print this out!</a>}
          content={() => this.componentRef}
        />
        <ComponentToPrint ref={(el) => (this.componentRef = el)} />
      </div>
 */

  return (
    <div className="container">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Kunde</th>
            <th scope="col">Adresse</th>
            <th scope="col">Bestellung</th>
            <th scope="col">Gesamtpreis</th>
            <th scope="col">Datum</th>
          </tr>
        </thead>
        <tbody>
          {items.map((order, index) => (
            <OrderTableRow order={order} index={index} key={index} />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default OrderTable;
