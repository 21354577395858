import React from "react";

const Contact = () => {
  return (
    <React.Fragment>
      {
        // Section 1 - Header
      }
      <div className="jumbotron jumbotrin fluid pHeader">
        <div className="container">
          <h1 className="display-4">Kontakt</h1>
          <p className="lead">Unsere Kontaktmöglichkeiten</p>
        </div>
      </div>
      {
        //Section 2 - Top-Text
      }
      <div className="container">
        <div className="row">
          <div className="col">
            <h2>Kontaktiere uns.</h2>
            <p>
              Wenn du eine Frage oder ein Anliegen hast oder dich als
              Zusteller*in anmelden willst, dann schreibe eine E-Mail an die
              jeweils angegebene E-Mail-Adresse oder nutze die richtigen
              Kontaktformulare.
            </p>
          </div>
        </div>
      </div>
      {
        //Section 3 - Collapse
      }
      <div className="container">
        <div id="accordion">
          <div className="card">
            <div className="card-header" id="headingOne">
              <h5 className="mb-0">
                <button
                  className="btn btn-link"
                  data-toggle="collapse"
                  data-target="#collapseOne"
                  aria-expanded="true"
                  aria-controls="collapseOne"
                >
                  <h4>Kontaktdaten</h4>
                </button>
              </h5>
            </div>

            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordion"
            >
              <div className="card-body">
                <h5>Kontaktiere uns mit den folgenden Kontaktdaten</h5>
                <br />
                <div className="ml-3">
                  <p>
                    E-Mail:{" "}
                    <a href="mailto:primaklima@kubusev.org ">
                      primaklima@kubusev.org
                    </a>
                  </p>
                  <p>Telefon: (+49) 0176 55529374</p>
                  <p>
                    <b>Ansprechpartner:</b> <br />
                    Silke Müller-Zimmermann <br />
                    Welzheimer Str. 43
                    <br />
                    71554 Weissach im Tal
                  </p>
                </div>
                <br />
                <span>
                  Alle Inhalte der E-Mails werden vertraulich und sicher
                  behandelt. Wir versuchen die Anfragen so schnell wie möglich
                  zu bearbeiten.
                </span>
                {/**
                 * 
                 * <form name="contact-message" method="post" data-netlify="true">
                  <input
                    type="hidden"
                    name="form-name"
                    value="contact-message"
                  />
                  <div className="form-group">
                    <label for="exampleInputEmail1">Email Adresse</label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="E-Mail eingeben"
                    />
                    <small id="emailHelp" className="form-text text-muted">
                      Alle Formulardaten werden vertraulich behandelt.
                    </small>
                  </div>
                  <div className="form-group">
                    <label for="name">Name</label>
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group">
                    <label for="name">Nachricht</label>
                    <textarea
                      className="form-control"
                      id="message"
                      placeholder="Nachricht"
                      rows="4"
                    />
                  </div>
                  <div className="form-check">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="exampleCheck1"
                    />
                    <label className="form-check-label" for="exampleCheck1">
                      Ich akzeptiere die AGB und Datenschutzbestimmungen.
                    </label>
                  </div>
                  <button type="submit" className="btn btn-primary">
                    Submit
                  </button>
                </form>
                 * 
                 * 
                 */}
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-header" id="headingTwo">
              <h5 className="mb-0">
                <button
                  className="btn btn-link collapsed"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  <h4>Kontaktformular</h4>
                </button>
              </h5>
            </div>
            <div
              id="collapseTwo"
              className="collapse"
              aria-labelledby="headingTwo"
              data-parent="#accordion"
            >
              <div className="card-body">
                <h4>Das Formular ist derzeit noch nicht verfügbar.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Contact;
