import React from "react";
import fireDb from "../../database/Firebase";

const OrderContent = ({ order, orderID, onRemove }) => {
  let date = new Date(order.date);

  const deleteOrder = () => {
    if (fireDb.auth().currentUser == null) return;
    fireDb
      .database()
      .ref("/users/" + fireDb.auth().currentUser.uid + "/orders")
      .remove();
    fireDb
      .database()
      .ref("/orders/" + orderID)
      .remove()
      .then(() => {
        onRemove();
      });
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <h4>Bestellwert</h4>
          <p>{parseFloat(order.total).toFixed(2)} €</p>
        </div>
        <div className="col-6">
          <h4>Bestelldatum</h4>
          <p>
            {date.getDate() +
              "." +
              (date.getMonth() + 1) +
              "." +
              date.getFullYear()}
          </p>
        </div>
      </div>
      <hr />
      <div className="row mt-2">
        <div className="col">
          <h4>Artikel</h4>
          <br />
          <table className="table">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Produkt</th>
                <th scope="col">Anzahl</th>
                <th scope="col">Einzelpreis</th>
                <th scope="col">Gesamtpreis</th>
              </tr>
            </thead>
            <tbody>
              {order.basket.map((item, index) => (
                <tr key={index}>
                  <th scope="row">{index + 1}</th>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>{item.price} €</td>
                  <td>{parseFloat(item.price * item.quantity).toFixed(2)} €</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="row">
        <div className="col mt-3">
          <div className="d-flex justify-content-center">
            <button className="btn btn-danger" onClick={deleteOrder}>
              Bestellung löschen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderContent;
