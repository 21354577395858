import React from "react";
import { useStateValue } from "../../StateProvider";

const BasketItem = ({ index, name, quantity, price, itemId }) => {
  const [, dispatch] = useStateValue();

  const removeFromBasket = () => {
    dispatch({
      type: "REMOVE_FROM_BASKET",
      id: itemId,
    });
  };

  return (
    <tr>
      <th scope="row">{index}</th>
      <td>{name}</td>
      <td>{quantity}</td>
      <td>{price} €</td>
      <td>{parseFloat(price * quantity).toFixed(2)} €</td>
      <td>
        <div className="btn btn-danger" onClick={removeFromBasket}>
          X
        </div>
      </td>
    </tr>
  );
};

export default BasketItem;
