import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import fireDb from "../../database/Firebase";

const AdminArticleRow = ({ itemSnapshot, index, editModal }) => {
  const item = itemSnapshot.val();
  const [active, setActive] = useState(item.active);
  const history = useHistory();

  const changeActive = () => {
    fireDb
      .database()
      .ref("/products/" + itemSnapshot.key + "/active")
      .set(!active)
      .then(() => setActive(!active));
  };

  const deleteItem = () => {
    fireDb
      .database()
      .ref("/products/" + itemSnapshot.key)
      .remove()
      .catch((err) => console.log(err));
    history.push("/adminarticles");
  };

  return (
    <React.Fragment>
      <tr key={index}>
        <th scope="row">{index + 1}</th>
        <td>{item.name} </td>
        <td>{item.description}</td>
        <td>{item.price} €</td>
        <td>
          <input
            className="form-check-input"
            type="checkbox"
            checked={active}
            onChange={() => changeActive()}
          />
        </td>
        <td>
          <div className="row">
            <button
              className="btn btn-primary mr-1"
              data-toggle="modal"
              data-target="#editModal"
              onClick={() => editModal(itemSnapshot)}
            >
              E
            </button>
            <button className="btn btn-danger" onClick={deleteItem}>
              D
            </button>
          </div>
        </td>
      </tr>
    </React.Fragment>
  );
};

export default AdminArticleRow;
