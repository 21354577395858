import React, { useState } from "react";
import { useStateValue } from "../../StateProvider";
import { Link } from "react-router-dom";
import "./product.css";

const Product = ({ item, itemId }) => {
  const [orderMode, setOrderMode] = useState(false);
  const [hasOrdered, setHasOrdered] = useState(true);
  const [, dispatch] = useStateValue();
  const [quantity, setQuantity] = useState(0);

  const addToBasket = () => {
    if (quantity < 1) return;
    // Add item to basket
    dispatch({
      type: "ADD_TO_BASKET",
      payload: {
        id: itemId,
        name: item.name,
        description: item.description,
        price: item.price,
        imageUrl: item.imageUrl,
        quantity: quantity,
      },
    });
    setHasOrdered(false);
  };

  return (
    <div className="mb-4 col-lg-4 col-md-6 col-12">
      <div className="card">
        <img className="card-img" src={item.imageUrl} alt={item.name} />
        <div className="card-body">
          <h4 className="card-title">{item.name}</h4>
          <p className="card-text">{item.description}</p>
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-success">
              <h5 className="mt-4">{item.price} € / Stück</h5>
            </div>
          </div>
          <hr />
          {orderMode ? (
            <div className="container">
              {hasOrdered ? (
                <div>
                  <div className="d-flex justify-content-center">
                    <div
                      className="btn btn-secondary amountLayout"
                      onClick={() => {
                        if (quantity > 0) setQuantity(quantity - 1);
                      }}
                    >
                      -
                    </div>
                    <input
                      className="form-control text-center amountInput amountLayout"
                      value={quantity}
                      onChange={(event) => setQuantity(event.target.value)}
                    />
                    <div
                      className="btn btn-secondary amountLayout"
                      onClick={() => setQuantity(quantity + 1)}
                    >
                      +
                    </div>
                  </div>
                  <div className="row mt-2 justify-content-center">
                    <div className="btn btn-success" onClick={addToBasket}>
                      In den Warenkorb
                    </div>
                  </div>
                </div>
              ) : (
                <div className="alert alert-success">
                  Der Artikel wurde deinem <Link to="/basket">Warenkorb</Link>{" "}
                  hinzugefügt
                </div>
              )}
            </div>
          ) : (
            <div className="d-flex justify-content-center">
              <div
                className="btn btn-primary"
                onClick={() => setOrderMode(true)}
              >
                Bestellen
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default Product;
