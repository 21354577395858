import React from "react";
import OrderTableScreen from "../../components/OrderTable/OrderTableScreen";

const OrdersPage = () => {
  return (
    <React.Fragment>
      <div className="container">
        <OrderTableScreen />
      </div>
    </React.Fragment>
  );
};

export default OrdersPage;
