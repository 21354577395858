import React, { useState } from "react";
import ImageUpload from "../ImageUpload/ImageUpload";
import fireDb from "../../database/Firebase";
import { Link, useHistory } from "react-router-dom";

const AddForm = () => {
  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [price, setPrice] = useState("");
  const [active, setActive] = useState(true);
  const [imageUrl, setImageUrl] = useState("");

  const [alert, setAlert] = useState("");
  const [success, setSuccess] = useState("");

  const addArticle = () => {
    if (
      name == null ||
      description == null ||
      price == null ||
      active == null ||
      imageUrl == null
    ) {
      setAlert("Alle Felder müssen ausgefüllt sein");
      return;
    }
    fireDb
      .database()
      .ref("products/")
      .push()
      .set({
        name: name,
        description: description,
        price: price,
        active: active,
        imageUrl: imageUrl,
      })
      .then(() => {
        setSuccess("Artikel erfolgreich hinzugefügt!");
        history.push("/admin");
        setName("");
        setDescription("");
        setPrice("");
        setImageUrl("");
      });
  };

  return (
    <div className="container">
      {alert && <div className="alert alert-danger">{alert}</div>}
      {success && <div className="alert alert-success">{success}</div>}
      <h3>Neues Produkt</h3>
      <hr />
      <form>
        <div className="form-group">
          <label>Produktname</label>
          <input
            type="email"
            className="form-control"
            aria-describedby="emailHelp"
            placeholder="Produktname"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Produktbeschreibung</label>
          <textarea
            className="form-control"
            rows="3"
            value={description}
            onChange={(event) => setDescription(event.target.value)}
          ></textarea>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Produktpreis</label>
              <div className="input-group mb-2 mr-sm-2">
                <div className="input-group-prepend">
                  <div className="input-group-text">€</div>
                </div>
                <input
                  type="text"
                  className="form-control"
                  placeholder="0.00"
                  value={price}
                  onChange={(event) => setPrice(event.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col align-self-center">
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                value={active}
                onChange={(event) => setActive(event.target.value)}
              />
              <label className="form-check-label">Artikel aktiv</label>
            </div>
          </div>
        </div>
      </form>
      <ImageUpload onSetUrl={(text) => setImageUrl(text)} />
      <br />
      <div className="row d-flex justify-content-around mt-4">
        <div to="/" className="btn btn-success" onClick={addArticle}>
          Hinzufügen
        </div>
        <Link to="/admin" className="btn btn-danger">
          Abbrechen
        </Link>
      </div>
    </div>
  );
};

export default AddForm;
