import * as firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyCuzQ-xiXa1x9zk21DMW75u_UaDoADjaOw",
  authDomain: "bread-shop-wkk.firebaseapp.com",
  projectId: "bread-shop-wkk",
  storageBucket: "bread-shop-wkk.appspot.com",
  messagingSenderId: "190234958583",
  appId: "1:190234958583:web:a8291415b2cb98497251f2",
};

var fireDb = firebase.initializeApp(firebaseConfig);

export default fireDb;
