import React, { useState, useEffect } from "react";
import { useStateValue } from "../../StateProvider";
import BasketItem from "../BasketItem/BasketItem";
import AccountInfo from "../AccountInfo/AccountInfo";
import { Link, useHistory } from "react-router-dom";
import fireDb from "../../database/Firebase";

const BasketContent = () => {
  const [{ basket }, dispatch] = useStateValue();
  const [total, setTotal] = useState(0.0);
  const [alertMessage, setAlertMessage] = useState();
  const history = useHistory();

  useEffect(() => {
    let cTotal = 0.0;
    for (let item of basket) {
      cTotal += parseFloat(item.price * item.quantity);
    }
    setTotal(cTotal);
  }, [basket]);

  const clearBasket = () => {
    dispatch({
      type: "CLEAR_BASKET",
    });
  };

  const setOrders = (orders) => {
    fireDb
      .database()
      .ref("/users/" + fireDb.auth().currentUser.uid)
      .update({
        orders: orders,
      })
      .then(() => {
        history.push("/thanks");
        clearBasket();
      });
  };

  const submitOrder = () => {
    if (fireDb.auth().currentUser == null) {
      setAlertMessage(
        "Es ist ein Fehler aufgetreten. Versuche es später noch einmal."
      );
      return;
    }
    let pushKey = fireDb.database().ref().child("orders").push().key;

    let basketContent = [];

    basket.forEach((element) => {
      basketContent = [...basketContent, element];
    });

    let order = {
      date: new Date(),
      basket: basketContent,
      total: total,
      userId: fireDb.auth().currentUser.uid,
    };
    var updates = {};
    updates["/orders/" + pushKey] = order;
    fireDb.database().ref().update(updates);

    fireDb
      .database()
      .ref("users/" + fireDb.auth().currentUser.uid + "/orders")
      .once("value")
      .then((snapshot) => {
        if (snapshot != null) {
          let userOrders = snapshot.val();
          if (userOrders == null) {
            userOrders = [];
          }
          userOrders = [...userOrders, pushKey];
          setOrders(userOrders);
        }
      });
  };

  return (
    <div className="container" style={{ minHeight: "60vh" }}>
      <div className="row">
        <div className="alert alert-warning">
          Bitte aktualisiere deine Account-Daten (Teilort und Telefonnummer) um
          die Zulieferung zu erleichtern oder bei Ausfall einer Lieferung
          informiert zu werden. <Link to="/edit/edit">Klicke hier</Link>
        </div>
      </div>
      <div className="row">
        <h3>Dein Warenkorb</h3>
      </div>
      <div className="alert alert-danger">{alertMessage}</div>
      <br />
      <div className="row">
        <div className="container ml-2">
          <div className="card">
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Produkt</th>
                  <th scope="col">Anzahl</th>
                  <th scope="col">Einzelpreis</th>
                  <th scope="col">Gesamtpreis</th>
                  <th scope="col"></th>
                </tr>
              </thead>
              <tbody>
                {basket.map((item, index) => (
                  <BasketItem
                    key={index}
                    index={index + 1}
                    name={item.name}
                    price={item.price}
                    quantity={item.quantity}
                    itemId={item.id}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <br />
      <div className="row">
        <AccountInfo />
      </div>
      <div className="container ml-0">
        <h4>Deine Bestellung</h4>
        <div className="card p-3">
          <div className="row">
            <div className="col">
              <p>
                <b>Wert der Bestellung</b>
              </p>
              <p>{total.toFixed(2)} €</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3 ml-2">
        <div className="d-flex justify-content-center align-items-center">
          <span className="btn btn-danger mr-3" onClick={clearBasket}>
            Warenkorb leeren
          </span>
          <button className="btn btn-success" onClick={submitOrder}>
            Bestellung anmelden
          </button>
        </div>
      </div>
    </div>
  );
};

export default BasketContent;
