import React, { useEffect, useState } from "react";
import ProductHeader from "../../components/productHeader/ProductHeader";
import ProductList from "../../components/productList/ProductList";
import { useStateValue } from "../../StateProvider";

const Products = () => {
  const [{ alertMessage }] = useStateValue();
  const [message, setMessage] = useState("");
  useEffect(() => {
    setMessage(alertMessage);
  }, [alertMessage]);

  return (
    <React.Fragment>
      <ProductHeader />
      <div className="container">
        {/* <div className="alert alert-danger">
          <b>Achtung:</b> Vom 18.12.2022 bis zum 5.1.2023 findet eine
          Lieferpause statt. Ab Samstag, dem 7.1.2023 werden wieder Brötchen
          geliefert, die bis Freitag 17:00 Uhr bestellt werden können. Wir
          wünschen ein frohes Fest und einen guten Start ins neue Jahr.
        </div> */}
        {message && (
          <div
            className="alert alert-danger"
            dangerouslySetInnerHTML={{ __html: message }}
          ></div>
        )}
      </div>
      <ProductList />
    </React.Fragment>
  );
};

export default Products;
