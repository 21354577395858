import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import fireDb from "../../database/Firebase";
import "./register.css";

const Register = () => {
  const history = useHistory();

  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [adress, setAdress] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [district, setDistrict] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);

  useEffect(() => {
    if (fireDb.auth().currentUser != null) {
      history.push("/account");
    }
  });

  const userRegister = () => {
    if (
      !name ||
      !lastName ||
      !adress ||
      !city ||
      !email ||
      !password ||
      !repeatPassword ||
      !acceptPrivacy
    ) {
      setAlertMessage("Fülle alle Felder aus!");
      return;
    }
    if (!validateEmail(email)) {
      setAlertMessage("Gib eine gültige E-Mail an");
      return;
    }
    fireDb
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => setAlertMessage("Erfolgreich registriert!"))
      .then(() => saveUserData())
      .catch((err) => console.log(err));
  };

  const saveUserData = () => {
    if (fireDb.auth().currentUser == null) return;
    fireDb
      .database()
      .ref("users/" + fireDb.auth().currentUser.uid)
      .set({
        name: name,
        lastName: lastName,
        email: email,
        adress: adress,
        city: city,
        phone: phone,
        district: district,
      })
      .then(() => history.push("/account"))
      .catch((err) => console.log(err));
  };

  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/;
    return re.test(String(email).toLowerCase());
  }

  return (
    <div style={{ minHeight: "60vh" }}>
      <div className="container text-center pt-5">
        <div className="col-sm-9 col-md-7 col-lg-5 mx-auto mt-5">
          <div className="card p-3 shadow border-0">
            <form className="form-signin">
              <img
                className="mb-4"
                src={require("../../img/wkk-icon.png")}
                alt=""
                width="72"
                height="72"
              />
              <h1 className="h3 mb-3 font-weight-normal">Registrieren</h1>
              <p>
                Durch das Angeben von Telefonnummer und Teilort, verinfachst Du
                das Zustellen deiner Lieferung und kannst bei einem Ausfall
                einer Lieferung informiert werden. Diese Angaben sind
                freiwillig.
              </p>
              {alertMessage ? (
                <div className="alert alert-danger">{alertMessage}</div>
              ) : (
                " "
              )}
              {
                //Vorname / Nachname
              }
              <label className="sr-only">Vorname</label>
              <input
                className="form-control mb-2"
                placeholder="Vorname"
                value={name}
                onChange={(event) => setName(event.target.value)}
                autoComplete="off"
              />

              <label className="sr-only">Nachname</label>
              <input
                className="form-control mb-2"
                placeholder="Nachname"
                value={lastName}
                onChange={(event) => setLastName(event.target.value)}
                autoComplete="off"
              />

              {
                //Adresse
              }
              <label className="sr-only">Straße und Hausnummer</label>
              <input
                className="form-control mb-2"
                placeholder="Straße und Hausnummer"
                value={adress}
                onChange={(event) => setAdress(event.target.value)}
                autoComplete="off"
              />

              <label className="sr-only">Ortschaft</label>
              <input
                className="form-control mb-2"
                placeholder="Ortschaft"
                value={city}
                onChange={(event) => setCity(event.target.value)}
                autoComplete="off"
              />

              <label className="sr-only">Teilort</label>
              <input
                className="form-control mb-2"
                placeholder="Teilort (optional)"
                value={district}
                onChange={(event) => setDistrict(event.target.value)}
                autoComplete="off"
              />

              {
                //E-Mail und Passwort
              }
              <label className="sr-only">Email address</label>
              <input
                type="email"
                className="form-control mb-2"
                placeholder="Email Adresse"
                value={email}
                onChange={(event) => setEmail(event.target.value)}
                autoComplete="off"
              />

              <label className="sr-only">Telefonnummer</label>
              <input
                type="phone"
                className="form-control mb-2"
                placeholder="Telefonnummer (optional)"
                value={phone}
                onChange={(event) => setPhone(event.target.value)}
                autoComplete="off"
              />

              <label className="sr-only">Password</label>
              <input
                type="password"
                className="mb-2 form-control"
                placeholder="Passwort"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                autoComplete="off"
              />

              <label className="sr-only">Password repeat</label>
              <input
                type="password"
                className="mb-2 form-control"
                placeholder="Passwort wiederholen"
                value={repeatPassword}
                onChange={(event) => setRepeatPassword(event.target.value)}
                autoComplete="off"
              />

              <input
                type="checkbox"
                className="form-check-input"
                checked={acceptPrivacy}
                onChange={() => setAcceptPrivacy(!acceptPrivacy)}
              />
              <label className="form-check-label" for="exampleCheck1">
                Ich Aktzeptiere die{" "}
                <Link to="/impressum">Datenschutzbedinungen</Link>
              </label>
              <div
                className="mt-3 btn btn-lg btn-primary btn-block"
                onClick={userRegister}
              >
                Registrieren
              </div>
              <div className="mt-3">
                <Link to="/login">Schon ein Konto? Hier zum Login</Link>
              </div>
              <p className="mt-5 mb-3 text-muted">© 2021</p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Register;
