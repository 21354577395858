import React from "react";
import "./productHeader.css";

const ProductHeader = () => {
  return (
    <div className="jumbotron jumbotrin fluid pHeader">
      <div className="container">
        <h1 className="display-4">Produkte</h1>
        <p className="lead">Die aktuelle Auswahl der Produkte.</p>
      </div>
    </div>
  );
};

export default ProductHeader;
