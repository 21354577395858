import React from "react";
import ReactToPrint from "react-to-print";
import OrderTableWrapper from "./OrderTableWrapper";

class OrderTableScreen extends React.Component {
  render() {
    return (
      <div>
        <ReactToPrint
          trigger={() => (
            <button className="btn btn-primary mb-4 mt-4 text-center" href="#">
              Liste Drucken
            </button>
          )}
          content={() => this.componentRef}
        />
        <OrderTableWrapper ref={(el) => (this.componentRef = el)} />
      </div>
    );
  }
}

export default OrderTableScreen;
