import React, { useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Header from "./components/header/Header";
import Home from "./pages/Home/Home";
import Products from "./pages/Products/Products";
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Account from "./pages/Account/Account";
import Admin from "./pages/Admin/Admin";
import PageFooter from "./components/Footer/PageFooter";
import Basket from "./pages/Basket/Basket";
import Thanks from "./pages/Thanks/Thanks";
import AdminArticles from "./pages/Admin/AdminArticles";
import OrdersPage from "./pages/Orders/OrdersPage";
import Contact from "./pages/contact/Contact";
import Imprint from "./pages/Imprint/Imprint";
import EditAccount from "./pages/Account/EditAccount";
import { useStateValue } from "./StateProvider";
import fireDb from "./database/Firebase";

function App() {
  const [, dispatch] = useStateValue();

  useEffect(() => {
    console.log("Loaded");
    fireDb
      .database()
      .ref("messages/")
      .once("value")
      .then((snap) => {
        if (snap.val().showAlert) {
          dispatch({
            type: "SET_ALERT",
            payload: snap.val().alertMessage,
          });
        }
      });
  }, [dispatch]);

  return (
    <Router>
      <Header />
      <Switch>
        <Route path="/checkout" />
        <Route path="/products" component={Products} />
        <Route path="/login" component={Login} />
        <Route path="/register" component={Register} />
        <Route path="/account" component={Account} />
        <Route path="/edit/edit" component={EditAccount} />
        <Route path="/contact" component={Contact} />
        <Route path="/impressum" component={Imprint} />
        <Route path="/basket" component={Basket} />
        <Route path="/thanks" component={Thanks} />
        <Route path="/admin" component={Admin} />
        <Route path="/adminarticles" component={AdminArticles} />
        <Route path="/adminorders" component={OrdersPage} />
        <Route component={Home} />
      </Switch>
      <PageFooter />
    </Router>
  );
}

export default App;
