import React, { useEffect, useState } from "react";
import fireDb from "../../database/Firebase";

const EditProduct = ({ item }) => {
  const [name, setName] = useState(item.val().name);
  const [description, setDescription] = useState(item.val().description);
  const [price, setPrice] = useState(item.val().price);
  const [key, setKey] = useState(item.key);
  const [status, setStatus] = useState();

  useEffect(() => {
    setName(item.val().name);
    setDescription(item.val().description);
    setPrice(item.val().price);
    setKey(item.key);
  }, [item]);

  const saveChanges = () => {
    fireDb
      .database()
      .ref("/products/" + key)
      .update({
        name: name,
        description: description,
        price: price,
      })
      .then(() => setStatus("Produkt aktialisiert."))
      .catch(() =>
        setStatus(
          "Ein Fehler ist aufgetreten! Bitte kontaktiere einen Administrator"
        )
      );
  };

  return (
    <div className="container">
      <div className="container">
        <h3>Neues Produkt</h3>
        <hr />
        {status && <div className="alert alert-secondary">{status}</div>}
        <form>
          <div className="form-group">
            <label>Produktname</label>
            <input
              className="form-control"
              value={name}
              onChange={(event) => setName(event.target.value)}
            />
          </div>
          <div className="form-group">
            <label>Produktbeschreibung</label>
            <textarea
              className="form-control"
              rows="3"
              value={description}
              onChange={(event) => setDescription(event.target.value)}
            ></textarea>
          </div>
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>Produktpreis</label>
                <div className="input-group mb-2 mr-sm-2">
                  <div className="input-group-prepend">
                    <div className="input-group-text">€</div>
                  </div>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="0.00"
                    value={price}
                    onChange={(event) => setPrice(event.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
        </form>
        <br />
        <div className="row d-flex justify-content-around mt-4">
          <button
            type="button"
            className="btn btn-secondary"
            data-dismiss="modal"
          >
            Schließen
          </button>
          <div to="/" className="btn btn-success" onClick={saveChanges}>
            Aktualisieren
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
