import React, { useEffect, useState } from "react";
import AdminContent from "../../components/AdminContent/AdminContent";
import fireDb from "../../database/Firebase";
import Unauthorized from "../Unauthorized/Unauthorized";

const Admin = () => {
  const [isAdmin, setIsAdmin] = useState();

  useEffect(() => {
    if (fireDb.auth().currentUser == null) return;
    fireDb
      .database()
      .ref("/users/" + fireDb.auth().currentUser.uid)
      .once("value")
      .then((snapshot) => {
        if (snapshot.exists()) {
          setIsAdmin(snapshot.val().admin);
        }
      });
  }, []);

  return (
    <div className="container">
      {!isAdmin ? <Unauthorized /> : <AdminContent />}
    </div>
  );
};

export default Admin;
