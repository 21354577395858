import React, { useEffect, useState } from "react";
import fireDb from "../../database/Firebase";
import OrderContent from "../OrderContent/OrderContent";

const Order = () => {
  const [loaded, setLoaded] = useState(false);
  const [order, setOrder] = useState();
  const [orderID, setOrderID] = useState();

  useEffect(() => {
    if (loaded) return;
    if (fireDb.auth().currentUser == null) return;
    fireDb
      .database()
      .ref("/users/" + fireDb.auth().currentUser.uid + "/orders")
      .once("value")
      .then((snapshot) => {
        if (snapshot != null) {
          getOrder(snapshot.val());
          setOrderID(snapshot.val());
          setLoaded(true);
        }
      })
      .catch((err) => console.log(err));
  });

  const getOrder = (orderId) => {
    if (orderId != null) {
      fireDb
        .database()
        .ref("/orders/" + orderId)
        .once("value")
        .then((snapshot) => {
          if (snapshot != null) {
            setOrder(snapshot.val());
          }
        });
    }
  };

  const removeOrder = () => {
    setOrder();
  };

  return (
    <div className="col mt-5">
      <div className="card border-0 shadow p-3">
        <div className="">
          <h2 className="text-center">Deine Bestellung</h2>
        </div>
        <hr />
        <br />
        {order ? (
          <OrderContent
            order={order}
            orderID={orderID}
            onRemove={removeOrder}
          />
        ) : (
          <div className="text-center">
            <p>Du hast derzeit keine Bestellung</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Order;
