import React from "react";

const Thanks = () => {
  const style = {
    minHeight: "65vh",
    marginBottom: 0,
    backgroundColor: "#fff",
  };

  return (
    <React.Fragment>
      <div className="jumbotron jumbotron-fluid" style={style}>
        <div className="container">
          <div className="h-100 d-flex justify-content-center align-items-center">
            <img src={require("./globe.png")} alt="Gruener Globus als Icon" />
          </div>

          <div className="row mt-4">
            <div className="col text-center">
              <h2>Vielen Dank für deine Bestellung</h2>
              <p>
                Durch deine Bestellung kannst du am Samstag ganz bequem auf
                deine
                <br />
                Brötchen warten. Und nebenbei tust du noch etwas Gutes für das
                Klima. <br /> Wir liefern zu Fuß oder mit dem Fahrrad, ganz ohne
                CO2-Ausstoß!
              </p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default Thanks;
