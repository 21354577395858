import React from "react";
import { Link } from "react-router-dom";

const LoginContext = () => {
  return (
    <div style={{ minHeight: "60vh" }}>
      <div className="container">
        <div className="row pt-5">
          <div className="col-sm-6 mx-auto mt-5">
            <div className="card shadow">
              <div className="card-body">
                <h4 className="card-title">Bitte melde dich an.</h4>
                <div className="card-text">
                  Du musst dich anmelden oder registrieren um eine Bestellung
                  aufgeben zu können.
                  <div className="d-flex justify-content-around mt-4">
                    <Link to="/login" className="btn btn-success">
                      Log-In
                    </Link>
                    <Link to="/register" className="btn btn-primary">
                      Register
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginContext;
