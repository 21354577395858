import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AddForm from "../../components/AddForm/AddForm";
import AdminOrders from "../AdminOrders/AdminOrders";
import fireDb from "../../database/Firebase";

const AdminContent = () => {
  const [alert, setAlert] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [alertToggle, setAlertToggle] = useState(false);

  const deleteOrders = () => {
    if (fireDb.auth().currentUser == null) return;
    fireDb
      .database()
      .ref("orders")
      .remove()
      .then(() => setAlert("Produkte erfolgreich gelöscht"))
      .catch(() =>
        setAlert(
          "Ein Fehler ist aufgetreten! Benachrichtige einen Administrator"
        )
      );
  };

  const updateAlert = () => {
    if (fireDb.auth().currentUser == null) return;
    fireDb
      .database()
      .ref("messages/")
      .set({
        alertMessage: alertMessage,
        showAlert: alertToggle,
      })
      .then(() => console.log("updated alert message"))
      .catch((err) => console.log(err));
    console.log(alertToggle);
  };

  useEffect(() => {
    fireDb
      .database()
      .ref("messages/")
      .once("value")
      .then((snapshot) => {
        setAlertMessage(snapshot.val().alertMessage);
        setAlertToggle(snapshot.val().showAlert);
      });
  }, []);

  return (
    <div className="container">
      <div className="row mt-4">
        <div className="col-sm-6">
          <div className="card border-0 shadow p-4">
            <AddForm />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="card border-0 shadow p-4">
            <h3>Statistik</h3>
            <hr />
            <AdminOrders />
          </div>
          <div className="card mt-3 border-0 shadow p-4">
            <h3>Admin-Aktionen</h3>
            <hr />
            <Link to="/adminarticles" className="btn btn-primary">
              Artikelliste
            </Link>
            <br />
            <button
              className="btn btn-primary"
              data-toggle="modal"
              data-target="#alertModal"
            >
              Alert-Nachricht
            </button>
            <br />
            <Link to="/adminorders" className="btn btn-primary">
              Bestellungsliste anfordern
            </Link>
            <br />
            <button
              className="btn btn-danger mr-1"
              data-toggle="modal"
              data-target="#deleteModal"
            >
              Alle Bestellungen zurücksetzen
            </button>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="deleteModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="deleteModalLable"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="deleteModalLable">
                Bestellungen löschen?
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="alert alert-secondary">{alert}</div>
              <p>
                Willst du wirklich alle Bestellungen zurücksetzen? Dies sollte
                erst nach dem ausliefern der Bestellungen durchgeführt werden.
              </p>
              <div className="row d-flex justify-content-around mt-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Schließen
                </button>
                <div className="btn btn-danger" onClick={deleteOrders}>
                  Bestellungen löschen
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="alertModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="alertModalLable"
        aria-hidden="true"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="alertModalLable">
                Alert Message
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="form-group">
                  <label>Alert Nachricht</label>
                  <textarea
                    type="text"
                    className="form-control"
                    aria-describedby="alertMessage"
                    placeholder="Alert Nachricht"
                    rows="3"
                    value={alertMessage}
                    onChange={(event) => setAlertMessage(event.target.value)}
                  />
                </div>
                <div className="form-group">
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      role="switch"
                      checked={alertToggle}
                      onChange={(e) => setAlertToggle(!alertToggle)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexSwitchCheckDefault"
                    >
                      Alert Nachticht anzeigen
                    </label>
                  </div>
                </div>
              </form>
              <div className="row d-flex justify-content-around mt-4">
                <button
                  type="button"
                  className="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Schließen
                </button>
                <button className="btn btn-success" onClick={updateAlert}>
                  Aktualisieren
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminContent;
