import React from "react";
import "./pageFooter.css";
import { Link } from "react-router-dom";

const PageFooter = () => {
  return (
    <div className="mt-5 pt-5 pb-5 footer">
      <div className="container">
        <div className="row">
          <div className="col-lg-5 col-xs-12 about-company">
            <h2>Samstagsbrötchen</h2>
            <p className="pr-5 text-white-50">
              Shop-Seite des Samstagsbrötchen-Projektes
            </p>
            <br />
            <div className="text-white-50">
              <span>Diese seite verwendet Bilder von:</span>
              <ul>
                <li>
                  <span>
                    <a href="https://unsplash.com/@impatrickt?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                      Patrick Tomasso
                    </a>{" "}
                    on{" "}
                    <a href="https://unsplash.com/?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                      Unsplash
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="https://unsplash.com/@wesual?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                      Wesual Click
                    </a>{" "}
                    on{" "}
                    <a href="https://unsplash.com/s/photos/bread?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                      Unsplash
                    </a>
                  </span>
                </li>
                <li>
                  <span>
                    <a href="https://unsplash.com/@anniespratt?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                      Annie Spratt
                    </a>{" "}
                    on{" "}
                    <a href="https://unsplash.com/s/photos/market?utm_source=unsplash&amp;utm_medium=referral&amp;utm_content=creditCopyText">
                      Unsplash
                    </a>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-xs-12 links">
            <h4 className="mt-lg-0 mt-sm-3">Direktlinks</h4>
            <ul className="m-0 p-0">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/products">Angebot</Link>
              </li>
              <li>
                <Link to="/register">Registrieren</Link>
              </li>
              <li>
                <Link to="/login">Login</Link>
              </li>
              <li>
                <Link to="/impressum">Impressum und Datenschutz</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-4 col-xs-12 location">
            <h4 className="mt-lg-0 mt-sm-4">Kontakt</h4>
            <p>
              Ansprechpartner: Silke Müller-Zimmermann
              <br />
              Welzheimer Str. 43
              <br />
              71554 Weissach im Tal
            </p>
            <p className="mb-0">
              <i className="fa fa-phone mr-3"></i>(+49) 0176 55529374
            </p>
            <p>
              <i className="fa fa-envelope-o mr-3"></i>
              <a href="mailto:primaklima@kubusev.org ">
                primaklima@kubusev.org
              </a>
            </p>
          </div>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 copyright">
            <p className="">
              <small className="text-white-50">
                © 2021. All Rights Reserved.
              </small>
            </p>
          </div>
          <div className="col-lg-4 copyright">
            <p className="">
              <small className="text-white-50">
                With support from{" "}
                <a href="https://niklas-schikora.de">Niklas Schikora</a>
              </small>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageFooter;
